<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="topbar">
      <van-row class="subcribe" v-if="subscribe === '0'">
        <van-col :span="24"
          ><van-notice-bar
            left-icon="info-o"
            text="关注公众号，即可领取20次匹配体验，5次信息查看，5次业务申请！"
          >
            <template #right-icon>
              <van-button
                icon="aim"
                class="btn"
                size="mini"
                type="primary"
                :color="COLOR_S1"
                @click="wppFocus()"
                plain="true"
                >立即关注
              </van-button></template
            >
          </van-notice-bar></van-col
        >
      </van-row>
      <div class="count">
        <van-row :gutter="1">
          <van-col :span="7" class="item color-m"
            >智能匹配<br />余{{ count.match }}次</van-col
          >
          <van-col :span="7" class="item color-s1"
            >信息查看<br />余{{ count.view }}次</van-col
          >
          <van-col :span="7" class="item color-s2"
            >业务申请<br />余{{ count.apply }}次</van-col
          >
          <van-col :span="3" class="buy" @click="buyPaid"
            >立即<br />充值</van-col
          >
        </van-row>
      </div>
    </div>
    <div class="output" ref="output">
      <div v-for="chat in chatList" :key="chat">
        <van-row class="left" v-if="chat.role === 'HPR'">
          <van-col :span="3" class="icon"
            ><svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-helper"></use></svg
          ></van-col>
          <van-col :span="16">
            <div class="text">
              {{ chat.content.lines }}
              <div
                v-for="item in chat.content.items"
                :key="item.code"
                class="item"
                @click="
                  itemClick(
                    chat.content.key,
                    chat.content.type,
                    item,
                    chat.content.code
                  )
                "
              >
                {{ item.text }}
              </div>
              <van-row
                v-if="
                  chat.content.items !== undefined &&
                  chat.content.items.length > 0 &&
                  chat.content.key === 'IST_RECRUIT_SIFT'
                "
                class="btn"
              >
                <van-col :span="8"
                  ><van-button
                    :color="COLOR_M"
                    size="mini"
                    icon="more-o"
                    @click="moreItem(chat.content.code, chat.content.key)"
                    >更多</van-button
                  ></van-col
                >
                <van-col :span="8"
                  ><van-button
                    :color="COLOR_S1"
                    size="mini"
                    icon="envelop-o"
                    @click="
                      recruitDeliver(chat.content.code, chat.content.total)
                    "
                    >投简历</van-button
                  ></van-col
                >
                <van-col :span="8"
                  ><van-button
                    :color="COLOR_S2"
                    size="mini"
                    icon="clock-o"
                    @click="
                      subscribeSubmit(chat.content.code, chat.content.total)
                    "
                    >订阅</van-button
                  ></van-col
                >
              </van-row>
              <van-row
                v-if="
                  chat.content.items !== undefined &&
                  chat.content.items.length > 0 &&
                  chat.content.key === 'IST_INFORM_SIFT'
                "
                class="btn"
              >
                <van-col :span="8"
                  ><van-button
                    :color="COLOR_M"
                    size="mini"
                    icon="more-o"
                    @click="moreItem(chat.content.code, chat.content.key)"
                    >更多</van-button
                  ></van-col
                >
                <van-col :span="8"
                  ><van-button
                    :color="COLOR_S1"
                    size="mini"
                    icon="sign"
                    @click="informSign(chat.content.code, chat.content.total)"
                    >报名</van-button
                  ></van-col
                >
                <van-col :span="8"
                  ><van-button
                    :color="COLOR_S2"
                    size="mini"
                    icon="clock-o"
                    @click="subscribeSubmit(chat.content.code)"
                    >订阅</van-button
                  ></van-col
                >
              </van-row>
              <van-row
                v-if="
                  chat.content.items !== undefined &&
                  chat.content.items.length > 0 &&
                  chat.content.key === 'IST_RECOMMEND_SIFT'
                "
                class="btn"
              >
                <van-col :span="12"
                  ><van-button
                    :color="COLOR_M"
                    size="mini"
                    icon="more-o"
                    @click="moreItem(chat.content.code, chat.content.key)"
                    >查看更多</van-button
                  ></van-col
                >
                <van-col :span="12"
                  ><van-button
                    :color="COLOR_S2"
                    size="mini"
                    icon="clock-o"
                    @click="subscribeSubmit(chat.content.code)"
                    >信息订阅</van-button
                  ></van-col
                >
              </van-row>
              <van-row
                v-if="
                  chat.content.key === 'IST_INFORM_SIGN' ||
                  chat.content.key === 'IST_RECRUIT_DELIVER'
                "
                class="btn"
              >
                <van-col :span="24"
                  ><van-button
                    :color="COLOR_M"
                    size="mini"
                    icon="more-o"
                    @click="resume"
                    >编辑简历</van-button
                  ></van-col
                >
              </van-row>
              <van-row
                v-if="
                  (chat.content.items === undefined ||
                    chat.content.items.length <= 0) &&
                  (chat.content.key === 'IST_INFORM_SIFT' ||
                    chat.content.key === 'IST_RECRUIT_SIFT')
                "
                class="btn"
              >
                <van-col :span="24"
                  ><van-button
                    :color="COLOR_M"
                    size="mini"
                    icon="more-o"
                    @click="subscribe(chat.content.code)"
                    >立即订阅</van-button
                  ></van-col
                >
              </van-row>
            </div>
          </van-col>
        </van-row>
        <van-row class="right" v-if="chat.role === 'USR'">
          <van-col :span="16" :offset="5">
            <div class="text">
              {{ chat.content }}
            </div>
          </van-col>
          <van-col :span="3" class="icon"
            ><svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-own"></use></svg
          ></van-col>
        </van-row>
      </div>
    </div>
    <div class="input">
      <van-row class="tabbar">
        <van-col :span="6" @click="home()"
          ><van-row>
            <van-col :span="6" class="icon"
              ><svg class="svg" aria-hidden="true">
                <use xlink:href="#icon-recruit"></use></svg
            ></van-col>
            <van-col :span="18" class="name">工作首页</van-col>
          </van-row>
        </van-col>
        <van-col :span="6" @click="resume()">
          <van-row>
            <van-col :span="6" class="icon"
              ><svg class="svg" aria-hidden="true">
                <use xlink:href="#icon-resume"></use></svg
            ></van-col>
            <van-col :span="18" class="name">个人简历</van-col>
          </van-row>
        </van-col>
        <van-col :span="6">
          <van-row @click="quickShow = true">
            <van-col :span="6" class="icon"
              ><svg class="svg" aria-hidden="true">
                <use xlink:href="#icon-talk"></use></svg
            ></van-col>
            <van-col :span="18" class="name">快捷用语</van-col>
          </van-row>
          <van-popup :show="quickShow" position="bottom">
            <van-picker
              :columns="quicks"
              @confirm="quickConfirm"
              @cancel="quickShow = false"
            />
          </van-popup>
        </van-col>
        <van-col :span="6" @click="own()">
          <van-row>
            <van-col :span="6" class="icon"
              ><svg class="svg" aria-hidden="true">
                <use xlink:href="#icon-own-1"></use></svg
            ></van-col>
            <van-col :span="18" class="name">个人中心</van-col>
          </van-row>
        </van-col>
      </van-row>
      <van-row>
        <van-col :span="3">
          <van-button
            :color="COLOR_M"
            type="primary"
            icon="volume-o"
          ></van-button
        ></van-col>
        <van-col :span="17"
          ><van-field
            v-model="text"
            class="text"
            center
            clearable
            placeholder="请输入您的要求"
          >
          </van-field
        ></van-col>
        <van-col :span="4">
          <van-button :color="COLOR_M" type="primary" @click="chat"
            >发送</van-button
          ></van-col
        >
      </van-row>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="HPR" module="HPR_HOME" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
