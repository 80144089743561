import { Field, Cell, Tag, Icon, NoticeBar, Popup, Picker } from 'vant'
import Login from '../../../module/intercept/Login.vue'
import Share from '../../../module/common/Share.vue'
import Loading from '../../../module/common/Loading.vue'
import LogVisit from '../../../module/common/LogVisit.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        [Field.name]: Field,
        [Cell.name]: Cell,
        [Tag.name]: Tag,
        [Icon.name]: Icon,
        [NoticeBar.name]: NoticeBar,
        [Popup.name]: Popup,
        [Picker.name]: Picker
    },
    data () {
        return {
            text: '',
            batchTotal: 0,
            tips: '',
            related: '',
            subscribe: '0',
            quickShow: false,
            quicks: [{ code: '1', text: '测试' }],
            roomKey: '',
            outputHeight: 0,
            vipEndTime: '',
            vipState: 'N',
            count: { match: 0, view: 0, apply: 0 },
            page: { current: 0, size: 10, last: 0 },
            chatList: [{ role: 'HPR', content: { lines: '您好' }, time: '2023-01-01 12:22:00' }]
        }
    },
    mounted () {
        var subscribe = window.sessionStorage.getItem(this.SESSION_SUBSCRIBE)
        if (subscribe !== undefined) {
            this.subscribe = subscribe
        }
        this.$refs.login.validate()
        this.$refs.share.default('main')
    },
    methods: {
        init () {
            var height = window.innerHeight
            this.$refs.output.style.height = height - 105 + 'px'
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            if (userCode === '0') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '未绑定手机，点击确认按钮立即绑定'
                }).then(() => {
                    this.$router.push({ path: '/mde/bind' })
                })
            } else {
                this.visit()
                this.retrieveQuick()
                this.retrievePaidCount()
                var that = this
                setInterval(function () {
                    that.pullMessage()
                }, 3000)
            }
        },
        buyPaid () {
            this.$router.push({ path: '/work/paid' })
        },
        wppFocus () {
            window.location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg4ODYxODQ5Nw==#wechat_redirect'
        },
        async chat () {
            if (this.text !== '') {
                if (this.count.match <= 0) {
                    this.$dialog.confirm({
                        title: '提示信息',
                        message:
                            '智能匹配次数不足，是否立即充值?'
                    })
                        .then(() => {
                            this.buyPaid()
                        })
                } else {
                    this.chatList.push({ role: 'USR', content: this.text, time: '2023-01-01 12:22:00' })
                    var pd = { text: this.text, instanceKey: 'RCT_MOC', roomKey: this.roomKey }
                    this.text = ''
                    this.$nextTick(() => {
                        var output = this.$refs.output
                        output.scrollTop = output.scrollHeight
                    })
                    const { data: res } = await this.$http.post(this.BMS_URL + '/helper/chat/converse', this.$qs.stringify(pd))
                    if (res.status === '200') {
                    }
                }
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '请输入内容'
                })
            }
        },
        async pullMessage () {
            var pd = { roomKey: this.roomKey }
            const { data: res } = await this.$http.post(this.BMS_URL + '/helper/chat/pullMessage', this.$qs.stringify(pd))
            if (res.status === '200') {
                res.data.forEach(element => {
                    var content = JSON.parse(element)
                    if (content.items !== undefined && content.items.length > 0 && this.related !== content.related) {
                        this.related = content.related
                        this.consumePaid('WORK_MATCH', 1)
                    }
                    this.chatList.push({ role: 'HPR', content: content, time: '2023-03-01 12:00:00' })
                })
                this.nextTick()
            } else {
                console.log(res.msg)
            }
        },
        async retrieveChatList () {
            this.loadingShow = true
            this.page.current = this.page.current + 1
            var pd = {
                instanceKey: 'RCT_MOC',
                current: this.page.current,
                size: this.page.size
            }
            var { data: res } = await this.$http.post(this.BMS_URL + '/helper/chat/retrieveUserChatList', this.$qs.stringify(pd))
            if (res.status === '200') {
                if (res.data.length > 0) {
                    this.chatList = res.data
                    this.page.last = res.page.last
                } else {
                    this.text = '你好'
                    this.chat()
                }
                this.nextTick()
            }
            this.loadingShow = false
        },
        async visit () {
            var pd = {
            }
            var that = this
            var { data: res } = await this.$http.post(this.BMS_URL + '/helper/visitor/visit', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.roomKey = res.data.roomKey
                this.retrieveChatList()
            } else {
                setInterval(function () {
                    that.visit()
                }, 3000)
            }
        },
        async itemClick (key, type, item, chatCode) {
            if (type === 'IST') {
                var userCode = window.sessionStorage.getItem(this.SESSION_USER)
                var itemCode = item.code
                var pd = { instanceKey: 'RCT_MOC', userCode: userCode, itemCode: itemCode, itemType: type }
                const { data: res } = await this.$http.post(this.BMS_URL + '/helper/view/validateView', this.$qs.stringify(pd))
                if (res.status === '200') {
                    var exit = res.data.exit
                    if (exit === 'N' && this.count.view > 0) {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '查看信息将扣除1次查看次数，是否继续?'
                        })
                            .then(() => {
                                this.consumePaid('WORK_VIEW', 1)
                                this.createHprView(itemCode, type, userCode, chatCode)
                                this.executeItemClick(key, type, item, chatCode)
                            })
                    } else if (exit === 'N' && this.count.view <= 0) {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '查看次数不足，是否立即充值?'
                        })
                            .then(() => {
                                this.buyPaid()
                            })
                    } else {
                        this.executeItemClick(key, type, item, chatCode)
                    }
                }
            } else if (key === 'IST_RECRUIT_DELIVER' || key === 'IST_INFORM_SIGN') {
                var pdo = { sourceCode: chatCode, sourceType: 'HPR' }
                const { data: res } = await this.$http.post(this.BMS_URL + '/overall/batch/validateBatch', this.$qs.stringify(pdo))
                if (res.status === '200') {
                    if (res.data.exit === 'N' && this.count.apply >= this.batchTotal) {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '批量申请将扣除' + this.batchTotal + '次申请次数，如未申请成功将返还申请次数，是否继续?'
                        })
                            .then(() => {
                                this.consumePaid('WORK_APPLY', this.batchTotal)
                                this.executeItemClick(key, type, item, chatCode)
                            })
                    } else if (res.data.exit === 'N' && this.count.apply < this.batchTotal) {
                        this.$dialog.confirm({
                            title: '提示信息',
                            message:
                                '申请次数不足，是否立即充值?'
                        })
                            .then(() => {
                                this.buyPaid()
                            })
                    } else if (res.data.exit === 'Y') {
                        this.chatList.push({ role: 'HPR', content: { lines: '已提交申请,请耐心等待' }, time: '2023-01-01 12:22:00' })
                        this.nextTick()
                    }
                }
            } else {
                this.executeItemClick(key, type, item, chatCode)
            }
        },
        executeItemClick (key, type, item, chatCode) {
            if (type === 'IST') {
                var itemType = 'JOB'
                if (key === 'IST_INFORM_SIFT') {
                    itemType = 'IFM'
                } else if (key === 'IST_RECRUIT_SIFT') {
                    itemType = 'RCT'
                }
                this.$router.push({ path: '/mde/institution/detail', query: { code: item.code, type: itemType } })
            } else if (key === 'CNT_CONTENT_SIFT') {
                window.sessionStorage.setItem(window.const.global.CONTENT_ARTICLE_INDEX_BACK, window.location.href)
                this.$router.push({ path: '/mde/content/article', query: { indexCode: item.code } })
            } else if (key === 'IST_RECRUIT_DELIVER') {
                this.createOperate('RRD', '{"personCode":"' + item.code + '"}', chatCode)
            } else if (key === 'IST_INFORM_SIGN') {
                this.createOperate('RRD', '{"personCode":"' + item.code + '"}', chatCode)
            } else if (key === 'TXT_HELPER_NO') {
                this.actionChose(item.related, item.key)
            } else if (key === 'IST_INSTITUTION_SIFT') {
                this.$router.push({ path: '/mde/institution/detail', query: { code: item.code } })
            } else if (key === 'TXT_HELPER_INTRODUCE') {
                this.text = item.text
                this.chat()
            }
        },
        home () {
            // var app = window.sessionStorage.getItem(this.SESSION_APP)
            // if (app === 'WPP_MOC') {
            //     this.$router.push({ path: '/work/home' })
            // }
            this.$router.push({ path: '/work/home' })
        },
        resume () {
            window.sessionStorage.setItem(window.const.global.MEMBER_PERSON_INDEX_BACK, window.location.href)
            this.$router.push({ path: '/mde/member/person', query: { profile: 'RECRUIT_NORMAL' } })
        },
        own () {
            this.$router.push({ path: '/work/own' })
        },
        async retrieveQuick () {
            var pd = { module: 'RECRUIT_QUICK', instanceKey: 'RCT_MOC', current: 1, size: 50 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/helper/quick/retrieveAPCQuickList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.quicks = res.data
            }
        },
        quickConfirm (val) {
            this.text = val.text
            this.quickShow = false
            this.chat()
        },
        moreItem (chatCode, actionKey) {
            this.$router.push({ path: '/mde/helper/item', query: { chatCode: chatCode, actionKey: actionKey } })
        },
        async recruitDeliver (code, total) {
            this.batchTotal = total
            const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/retrievePersonList')
            if (res.status === '200') {
                if (res.data.length > 0) {
                    var items = []
                    res.data.forEach(element => {
                        items.push({ text: element.name, code: element.code })
                    })
                    this.chatList.push({ role: 'HPR', content: { lines: '请选择要投递的简历', items: items, key: 'IST_RECRUIT_DELIVER', code: code }, time: '2023-01-01 12:22:00' })
                } else {
                    this.chatList.push({ role: 'HPR', content: { lines: '未找到简历，请您先完善简历' }, time: '2023-01-01 12:22:00', code: code })
                }
            }
            this.nextTick()
        },
        async informSign (code, total) {
            this.batchTotal = total
            const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/retrievePersonList')
            if (res.status === '200') {
                if (res.data.length > 0) {
                    var items = []
                    res.data.forEach(element => {
                        items.push({ text: element.name, code: element.code })
                    })
                    this.chatList.push({ role: 'HPR', content: { lines: '请选择要报名简历', items: items, key: 'IST_INFORM_SIGN', code: code }, time: '2023-01-01 12:22:00' })
                } else {
                    this.chatList.push({ role: 'HPR', content: { lines: '未找到简历，请您先完善简历' }, time: '2023-01-01 12:22:00', code: code })
                }
            }
            this.nextTick()
        },
        async subscribeSubmit (code) {
            var pd = { type: 'SCB', attach: '', chatCode: code }
            const { data: res } = await this.$http.post(this.BMS_URL + '/helper/chat/createChatOperate', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.chatList.push({ role: 'HPR', content: { lines: '已提交订阅，请耐心等待' }, time: '2023-01-01 12:22:00' })
            } else {
                if (res.code === '4001') {
                    this.chatList.push({ role: 'HPR', content: { lines: '已提交订阅，请勿重复提交' }, time: '2023-01-01 12:22:00' })
                } else if (res.code === '4002') {
                    this.chatList.push({ role: 'HPR', content: { lines: '系统出错提交失败，我将尽快回复' }, time: '2023-01-01 12:22:00' })
                }
            }
            this.nextTick()
        },
        async createOperate (type, attach, chatCode) {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: user, sourceCode: chatCode, sourceType: 'HPR', type: type, attach: attach }
            const { data: res } = await this.$http.post(this.BMS_URL + '/overall/batch/createBatch', this.$qs.stringify(pd))
            if (res.status === '200') {
                if (type === 'RRD') {
                    this.chatList.push({ role: 'HPR', content: { lines: '已提交简历投递申请，请耐心等待' }, time: '2023-01-01 12:22:00' })
                } else if (type === 'IRS') {
                    this.chatList.push({ role: 'HPR', content: { lines: '已提公招报名申请，请耐心等待' }, time: '2023-01-01 12:22:00' })
                }
            } else {
                if (res.code === '4001') {
                    this.chatList.push({ role: 'HPR', content: { lines: '已提交申请，请勿重复提交' }, time: '2023-01-01 12:22:00' })
                } else if (res.code === '4002') {
                    this.chatList.push({ role: 'HPR', content: { lines: '系统出错提交失败，我将尽快回复' }, time: '2023-01-01 12:22:00' })
                }
            }
            this.nextTick()
        },
        async actionChose (chatCode, actionKey) {
            var pd = { code: chatCode }
            const { data: res } = await this.$http.post(this.BMS_URL + '/helper/chat/retrieveChat', this.$qs.stringify(pd))
            if (res.status === '200') {
                if (actionKey === 'IST_RECRUIT_SIFT') {
                    this.text = res.data.content + '招聘'
                } else if (actionKey === 'IST_INFORM_SIFT') {
                    this.text = res.data.content + '公招'
                } else if (actionKey === 'IST_INSTITUTION_SIFT') {
                    this.text = res.data.content + '单位'
                } else if (actionKey === 'CNT_WORK_SIFT') {
                    this.text = res.data.content + '工作'
                }
                this.chat()
            }
        },
        async retrievePaidCount () {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { userCode: userCode, appKey: 'WPP_MOC', paidKeys: 'WORK_MATCH,WORK_VIEW,WORK_APPLY' }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/retrieveUserCusPaidList', this.$qs.stringify(pd))
            if (res.status === '200') {
                res.data.forEach(element => {
                    if (element.key === 'WORK_MATCH') {
                        this.count.match = element.surplusCount
                    } else if (element.key === 'WORK_VIEW') {
                        this.count.view = element.surplusCount
                    } else if (element.key === 'WORK_APPLY') {
                        this.count.apply = element.surplusCount
                    }
                })
            } else if (res.code === '4002') {
                var paids = [{ code: '101001', count: 10 }, { code: '101002', count: 1 }, { code: '101003', count: 1 }]
                this.receivePaid(userCode, JSON.stringify(paids))
            }
        },
        async receivePaid (userCode, paids) {
            var pd = { appKey: 'WPP_MOC', userCode: userCode, paids: paids }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/buyPaid', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已为您自动领取新用户体验套餐'
                })
                this.retrievePaidCount()
            }
        },
        async consumePaid (key, count) {
            var userCode = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { appKey: 'WPP_MOC', paidKey: key, userCode: userCode, count: count }
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/paid/consumePaid', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.retrievePaidCount()
            }
        },
        async createHprView (itemCode, itemType, userCode, chatCode) {
            var pd = { instanceKey: 'RCT_MOC', chatCode: chatCode, userCode: userCode, itemCode: itemCode, itemType: itemType }
            const { data: res } = await this.$http.post(this.BMS_URL + '/helper/view/createView', this.$qs.stringify(pd))
            if (res.status === '200') {
            }
        },
        nextTick () {
            this.$nextTick(() => {
                var output = this.$refs.output
                output.scrollTop = output.scrollHeight
            })
        }
    }
}
